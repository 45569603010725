import axios from 'axios';
import moment from 'moment';

import config from '../config';
import {
  LOGOUT,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  GET_LOGS,
  GET_LOGS_SUCCESS, GET_DEALER, GET_DEALER_SUCCESS,
} from './actionTypes';

const baseUrl = config.baseApiUrl;

export const getLogs = (dealerId, fromDate, toDate) => {
  return (dispatch, getState) => {
    dispatch({type: GET_LOGS});

    let headers = {
      Authorization: `Bearer ${getState().auth.token}`
    };

    let fromDateMoment = moment(fromDate);
    let toDateMoment = moment(toDate);

    axios.get(`${baseUrl}/api/v1/logs?dealerId=${dealerId}&fromDate=${fromDateMoment.toJSON()}&toDate=${toDateMoment.toJSON()}`, {headers})
        .then(result => {
          dispatch({type: GET_LOGS_SUCCESS, payload: result.data});
        })
  }
}

export const login = (username, password) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN
    });

    axios.post(`${baseUrl}/api/v1/auth/login`, { username, password})
      .then(result => {
        dispatch({ type: LOGIN_SUCCESS, payload: { token: result.data.token }});
      }).catch(() => {
          dispatch({ type: LOGIN_FAILED,
            payload: {
              error: 'Unable to log in'
            }
          });
      });
  };
}

export const getDealers = () => {
  return (dispatch, getState) => {
    dispatch({type: GET_DEALER});

    let headers = {
      Authorization: `Bearer ${getState().auth.token}`
    };

    axios.get(`${baseUrl}/api/v1/logs/dealers`, {headers})
        .then(result => {
          dispatch({type: GET_DEALER_SUCCESS, payload: result.data});
      })
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  }
}
