import {LOGIN, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT} from '../actionTypes';

const initialState = {
  token: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        error: null
      };
    }
    case LOGIN_SUCCESS: {
      const { token } = action.payload;
      return {
        ...state,
        token
      };
    }
    case LOGIN_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        token: null,
        error
      }
    }
    case LOGOUT: {
      return {
        ...state,
        token: null
      }
    }
    default:
      return state;
  }
}
