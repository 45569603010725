import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import {getDealers, getLogs} from '../redux/actions';

class LogsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: this.props.logs,
      currentSortKey: '',
      reversed: false,
      fromDate: moment().subtract(1, 'month').toDate(),
      toDate: moment().toDate(),
      dealerId: 0
    };
    this.onSort = this.onSort.bind(this)

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!this.props.dealersIsLoading && this.state.dealerId === 0 && this.props.dealers.length > 0){
      this.setState({
        dealerId: ""
      })
    }
  }

  componentDidMount() {
    this.props.getDealers();
  }

  onSort(event, sortKey){
    const logs = this.props.logs;
    logs.sort((a,b) => a[sortKey]?.toString().localeCompare(b[sortKey]?.toString()));

    let reversed = false;
    if (sortKey === this.state.currentSortKey && !this.state.reversed) {
      logs.reverse();
      reversed = true;
    }

    this.setState({logs, currentSortKey: sortKey, reversed})
  }

  handleFromDate = date => {
    this.setState({
      fromDate: date
    });
  }

  handleToDate = date => {
    this.setState({
      toDate: date
    })
  }

  handleDealer = event => {
    this.setState({
      dealerId: event.target.value
    })
  }

  search = () => {
    if(this.state.dealerId === 0) {
      return;
    }
    this.props.getLogs(this.state.dealerId, this.state.fromDate, this.state.toDate);
  }

  isLoading = () => {
    if(this.props.logsIsLoading){
      return <h2>Searching...</h2>
    }
  }

  renderDealersSelect = () => {
    if(this.props.dealersIsLoading){
      return <p>Is fetching dealers...</p>
    }

    let allOption = <option value={""} key={0}>Alle</option>;

    let options = this.props.dealers.map((d) => <option value={d.id} key={d.id}>{d.name}</option>);
    options = [allOption, ...options];

    return (
      <select onChange={this.handleDealer.bind(this)}>
        {options}
      </select>
    );
  }

  render() {
    let logs;
    if(this.state.logs.length > 0 ){
      logs = this.state.logs;
    } else {
      logs = this.props.logs;
    }

    const pointer = {
      cursor: 'pointer'
    };

    return (
        <div>
          <h1>Logs</h1>
          <div>
            <div>
              <label>Dealers</label>
              {this.renderDealersSelect()}
            </div>
            <div>
              <label>From Date</label>
              <DatePicker
                  selected={this.state.fromDate}
                  onChange={this.handleFromDate}
              />
            </div>
            <div>
              <label>To Date</label>
              <DatePicker
                  selected={this.state.toDate}
                  onChange={this.handleToDate}
              />
            </div>
            <button onClick={this.search}>
              Search
            </button>
          </div>

          <Table>
            <thead>
              <tr>
                <th style={pointer} onClick={e => this.onSort(e, 'applicationName')}>Application Name</th>
                <th style={pointer} onClick={e => this.onSort(e, 'dealerName')}>Dealer Name</th>
                <th style={pointer} onClick={e => this.onSort(e, 'roleKey')}>Role Key</th>
                <th style={pointer} onClick={e => this.onSort(e, 'endpoint')}>Endpoint</th>
                <th>Request</th>
                <th>Response</th>
                <th style={pointer} onClick={e => this.onSort(e, 'timestamp')}>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {logs.map(log =>
                  <tr key={log.id}>
                    <td>{log.applicationName}</td>
                    <td>{log.dealerName}</td>
                    <td>{log.roleKey}</td>
                    <td>{log.endpoint}</td>
                    <td>{log.request}</td>
                    <td>{log.response}</td>
                    <td>{log.timestamp}</td>
                  </tr>
              )}
            </tbody>
          </Table>
          {this.isLoading()}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {logs} = state.log;
  const {dealers} = state.dealer;
  return {
    logs,
    dealers,
    logsIsLoading: state.log.isLoading,
    dealersIsLoading: state.dealer.isLoading
  }
}

export default connect(
    mapStateToProps,
    { getLogs, getDealers }
)(LogsTable)
