import {GET_LOGS, GET_LOGS_SUCCESS} from '../actionTypes';

const initialState = {
  logs: [],
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LOGS: {
      return {
        ...state,
        isLoading: true,
        logs: []
      }
    }
    case GET_LOGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        logs: action.payload
      };
    }
    default:
      return state;
  }
}
