import React from 'react';
import { connect } from 'react-redux';
import { login } from '../redux/actions';

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {username: '', password: ''}
  }

  updateUsername = username => {
    this.setState({ username });
  }

  updatePassword = password => {
    this.setState({ password });
  }

  handleLogin = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);

    this.setState({username: '', password: ''});
  }

  render() {
    return (
        <div>
          <h1>Login</h1>
          <form onSubmit={this.handleLogin}>
            <div>
              <label>Username</label>
              <input onChange={e => this.updateUsername(e.target.value)} value={this.state.username} />
            </div>
            <div>
              <label>Password</label>
              <input onChange={e => this.updatePassword(e.target.value)} value={this.state.password} type="password" />
            </div>
            <input type="submit" value="Login" />
            <p style={{color: 'red'}}>{this.props.errorMessage}</p>
          </form>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { error } = state.auth;

  return {
    errorMessage: error
  }
}

export default connect(
    mapStateToProps,
    { login }
)(Login)
