import {GET_DEALER, GET_DEALER_SUCCESS} from '../actionTypes';

const initialState = {
  dealers: [],
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DEALER: {
      return {
        ...state,
        isLoading: true,
        dealers: []
      }
    }
    case GET_DEALER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dealers: action.payload
      };
    }
    default:
      return state;
  }
}
