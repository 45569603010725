import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import Login from './components/Login';
import LogsTable from './components/LogsTable';

class App extends React.Component {
  render() {
    if (!this.props.isLoggedIn) {
      return (
          <Login/>
      );
    }

    return (
        <LogsTable />
    );
  }
}

const mapStateToProps = (state) => {
  const { token } = state.auth;

  return {
    isLoggedIn: token !== null
  }
}

export default connect(
  mapStateToProps
)(App);
